/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';

import BounceLoader from 'react-spinners/BounceLoader';

interface IProps {
  color?: string;
  size?: string;
  show?: boolean;
}

export default (props: IProps) => {
  const { color, size, show = false } = props;
  const [isLoading, setIsLoading] = useState(show);
  let timeoutTracker: NodeJS.Timeout;

  useEffect(() => {
    // do not show loader for a second
    timeoutTracker = setTimeout(() => setIsLoading(true), 1000);

    return () => {
      if (timeoutTracker) clearTimeout(timeoutTracker);
    };
  }, []);

  return (
    <div
      css={css`
      align-items: center;
      display: flex;
      justify-content: center;
    `}
    >
      <BounceLoader
        loading={isLoading}
        color={color || '#123abc'}
        size={size === 'tiny' ? 15 : size === 'small' ? 25 : 65}
      />
    </div>
  );
};
