/**
 * to handle time conversions
 * NOTE: In Postgres Value of Datetime is stored as UTC , The Timezone of the server is considered
 * and the input is to be done in the local time but postgres stores it in UTC Big INT 8 byte
 * When recovering it sees the client timezone and converts it to the same time zone
 *
 */

import moment, { MomentInput } from 'moment-timezone';

const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm A';
const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
const DEFAULT_TIME_FORMAT = 'hh:mm A';
export function setDefaultTimezone() {
  const timezone = moment.tz.guess(); //"Europe/Vienna" //America/New_York
  //console.log("Guessed Timezone: "+timezone)
  moment.tz.setDefault(timezone);
}

//get local date time as formatted string
export function getCurrentLocaleDateTime(format: string = DEFAULT_DATE_TIME_FORMAT) {
  return moment().format(format);
}

/**
 * covert local date to UTC and get its epoch value
 * @param localDate
 */
export function localDatetoUTCEpoch(localDate: MomentInput) {
  return moment.utc(localDate).valueOf();
}
/**
 * convert local date to UTC and get a formatted string
 * @param localDate - the local date value of MomentInput type
 * @param format - the formatted you want the return string to be
 */
export function localToUTCDate(localDate: MomentInput, format: string) {
  return moment.utc(localDate).format(format || DEFAULT_DATE_TIME_FORMAT);
}
/**
 * Gives current EPOCH Time in UTC
 */
export function getCurrentEpoch() {
  return moment.utc();
}
/**
 * Joins date time and formats them according to the DEFAULT_DATE_TIME_FORMAT
 * @param datetime  - input that needs conversion
 * @param dateTimeJoiner - the joiner between date and time
 * @param onlyDateTime - if true returns an array with 0=>as date and 1=> as time without joiner
 */
export function getDateTime(datetime?: MomentInput, dateTimeJoiner = 'at', onlyDateTime = false): string[] | string {
  let res: string | string[];
  if (datetime)
    if (!onlyDateTime) {
      res = moment(datetime).format(DEFAULT_DATE_FORMAT + ' [' + dateTimeJoiner + '] ' + DEFAULT_TIME_FORMAT);
    } else {
      const date = moment(datetime).format(DEFAULT_DATE_FORMAT);
      const time = moment(datetime).format(DEFAULT_TIME_FORMAT);
      res = [date, time];
    }
  else res = '';

  return res;
}
