import React from 'react';
import useGlobalHook, { Store } from 'use-global-hook';

import { TEventData } from 'types';

// state
type State = {
  event: TEventData | null;
};

const eventInitialState: { event: TEventData | null } = { event: null };

// actions
type Actions = {
  setEvent: (TEventData: TEventData) => void;
  removeEvents: () => void;
};

const setEvent = (store: Store<State, Actions>, event: TEventData) => {
  store.setState({ event });
};

const flushEvents = (store: Store<State, Actions>) => {
  store.setState({ event: null });
};

const eventActions = { setEvent, flushEvents };

// store
export const useEventStore = useGlobalHook<State, Actions>(React, eventInitialState, eventActions);
