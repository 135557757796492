/** @jsx jsx */
import React, { ReactChild, ReactFragment, ReactPortal, Suspense } from 'react';
import { css, jsx } from '@emotion/core';

import Spinner from 'fragments/Spinner';

interface IProps {
  children: ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;
}

export default function (props: IProps) {
  return (
    <Suspense
      fallback={
        <div css={css`margin-top: 25vh; width: 100%;`}>
          <Spinner />
        </div>
      }
    >
      {props.children}
    </Suspense>
  );
}
