/** @jsx jsx */
import React, { useEffect } from 'react';
import { FaChevronUp } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import { css, jsx } from '@emotion/core';
import useWindowScroll from '@react-hook/window-scroll';
import { motion } from 'framer-motion';
import ReactTooltip from 'react-tooltip';

import { DARK_ICON } from 'constants/colors';

interface IProps {
  history: History;
}

function ScrollToTop(props: IProps) {
  const { history } = props;
  const windowHeight = window.innerHeight;
  const scrollY = useWindowScroll(60);
  const showSTT = scrollY * 2 > windowHeight;

  useEffect(() => {
    const unlisten = history.listen(() => {
      setTimeout(() => {
        window.scroll({ top: 0, left: 0 });
      }, 100);
    });
    return () => {
      unlisten();
    };
  }, []);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return showSTT ? (
    <React.Fragment>
      <ReactTooltip />
      <motion.div
        data-tip="Scroll to Top"
        whileHover={{ scale: 2 }}
        css={css`
        background-color: ${DARK_ICON};
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 2vh;
        right: 2vh;
        border-radius: 10px;
        height: 15px;
        width: 15px;
      `}
        onClick={scrollToTop}
      >
        <FaChevronUp size={7.5} color="white" />
      </motion.div>
    </React.Fragment>
  ) : null;
}

export default withRouter(ScrollToTop);
