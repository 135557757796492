import { COURSES_BASE_URL } from 'constants/app';

export function cleanURL(url?: string): string {
  if (!url) return '';
  if (url.startsWith('/')) url = url.substr(1);
  if (url.endsWith('/')) url = url.slice(0, -1);
  return url;
}

export function getDirFromPath(path: string) {
  if (path.endsWith('/')) path = path.slice(0, -1);
  return path.substring(0, path.lastIndexOf('/') + 1);
}

export function getCurrentPath() {
  let currentPath = cleanURL(location.pathname);
  // remove routes leading up to and including the basepath
  if (COURSES_BASE_URL) {
    const currentPathComponents = currentPath.split('/');
    const basePathIndex = currentPathComponents.findIndex(pc => pc === COURSES_BASE_URL);
    if (basePathIndex !== -1) currentPath = currentPathComponents.slice(basePathIndex).join('/');
  }

  return currentPath;
}
