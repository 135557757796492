import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ScrollToTop from 'fragments/ScrollToTop';
import Suspense from 'fragments/Suspense';
import Preloader from 'components/Preloader';
import TopBar from 'components/Topbar';
import NavCatcher from 'components/NavCatcher';
import {
  APPLICATIONS_PATH,
  LOGIN_PATH,
  PRODUCTS_PATH,
  SUBMISSIONS_TA_PATH,
  SCHEDULE_PATH,
  NOTES_PATH,
} from 'constants/app';
import 'styles/Courseware.scss.css';
import 'styles/App.css';

const Home = React.lazy(() => import('components/Home'));
const Courseware = React.lazy(() => import('components/Courseware'));
const SubmissionTA = React.lazy(() => import('components/Submission/TA'));
const TempProducts = React.lazy(() => import('components/TempProducts'));
const TempApplications = React.lazy(() => import('components/TempApplications'));
const TempLogin = React.lazy(() => import('components/TempLogin'));
const Schedule = React.lazy(() => import('components/Schedule'));
const Notes = React.lazy(() => import('components/Notes'));

toast.configure({
  // autoClose: 8000,
});

export default function App() {
  return (
    <Preloader>
      <Router>
        <div>
          <div id="event-portal" />
          <NavCatcher />
          <TopBar />
          <Switch>
            <Route exact path="/">
              <Suspense>
                <Home />
              </Suspense>
            </Route>
            <Route path={SUBMISSIONS_TA_PATH}>
              <Suspense>
                <SubmissionTA />
              </Suspense>
            </Route>
            <Route path={PRODUCTS_PATH}>
              <Suspense>
                <TempProducts />
              </Suspense>
            </Route>
            <Route path={APPLICATIONS_PATH}>
              <Suspense>
                <TempApplications />
              </Suspense>
            </Route>
            <Route path={LOGIN_PATH}>
              <Suspense>
                <TempLogin />
              </Suspense>
            </Route>
            <Route path={SCHEDULE_PATH}>
              <Suspense>
                <Schedule />
              </Suspense>
            </Route>
            <Route path={NOTES_PATH}>
              <Suspense>
                <Notes isSidebar={false} />
              </Suspense>
            </Route>
            <Route
              exact
              strict
              path="/:url*"
              render={props => {
                return <Redirect to={`${props.location.pathname}/${props.location.search}`} />;
              }}
            />
            <Route>
              <Suspense>
                <Courseware />
              </Suspense>
            </Route>
          </Switch>
        </div>
        <div id="discourse-sidebar-portal" />
        <ScrollToTop />
      </Router>
    </Preloader>
  );
}
