/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/core';
import jwtDecode from 'jwt-decode';

import useStore from 'globalstate';

import { getCookieValue } from 'utils/cookies';

import { APP_BASENAME, getAuthRefreshURL } from 'constants/app';

type AuthCookie = {
  aud: string;
  dashboard: string;
  tokens: string;
  exp: number;
  full: string;
  iat: number;
  iss: string;
  roles: string;
  sub: string;
  userId: number;
  username: string;
  name: string;
};

interface IProps {
  children: JSX.Element;
}

export default function Preloader(props: IProps) {
  const [resettingToken, setResettingToken] = useState<boolean>(false);
  const [hasPreloaded, sethasPreloaded] = useState<boolean>(false);
  const [, Actions] = useStore();

  const resetCookie = async () => {
    setResettingToken(true);
    localStorage.setItem('lastReset', Date.now().toString());
    window.location.replace(getAuthRefreshURL(window.location.href));
  };

  const maybeResetCookie = () => {
    const maybeAuthCookie = getCookieValue('border-patrol-jwt');
    if (!maybeAuthCookie) return;

    const lastResetRaw = localStorage.getItem('lastReset');
    if (!lastResetRaw) return resetCookie();

    const lastReset = parseInt(lastResetRaw);
    const oneMinute = 1000 * 60;
    if (lastReset < Date.now() + oneMinute) return;

    resetCookie();
  };

  const checkAuthentication = () => {
    const maybeAuthCookie = getCookieValue('border-patrol-jwt');
    if (!maybeAuthCookie) return Actions.setState({ userDetails: null, isAuthenticated: false, token: null });

    const authInfo: AuthCookie = jwtDecode(maybeAuthCookie);
    const userDetails = {
      username: authInfo.username,
      name: authInfo.name,
    };
    const cookieHasExpired = authInfo.exp > Date.now();
    if (cookieHasExpired) return resetCookie();

    const isAuthenticated = !!authInfo.username;
    const isTA = authInfo.tokens ? authInfo.tokens.split(',').includes('ta') : false;
    Actions.setState({ isAuthenticated, isTA, userDetails, token: maybeAuthCookie });
  };

  useEffect(() => {
    maybeResetCookie();
    checkAuthentication();
    sethasPreloaded(true);
  }, []);

  if (!hasPreloaded)
    return (
      <div
        css={css`
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
      >
        <img
          src={`${APP_BASENAME}/logo.svg`}
          css={css`
          width: 6vw;
          height: auto;
          @media (max-width: 768px) {
            width: 66vw;
          }
        `}
        />
      </div>
    );

  if (resettingToken)
    return (
      <div
        css={css`
        display: fixed;
        height: 100vh;
        width: 100vw;
      `}
      >
        <div
          css={css`
          height: 100vh;
          width: 100vw;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
        >
          <img
            src={`${APP_BASENAME}/logo.svg`}
            css={css`
            width: 6vw;
            height: auto;
            @media (max-width: 768px) {
              width: 66vw;
            }
          `}
          />
        </div>
      </div>
    );

  return props.children;
}
