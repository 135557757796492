import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import * as Sentry from '@sentry/browser';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { setDefaultTimezone } from 'utils/datetime-handler';
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

ReactModal.setAppElement('body');

if (process.env.REACT_APP_ENV === 'production')
  Sentry.init({ dsn: 'https://2ea6cdae8b674df6b46887b4dd62241d@o387419.ingest.sentry.io/5222617' });

if (document.readyState === 'loading')
  // Loading hasn't finished yet
  document.addEventListener('DOMContentLoaded', startup);
// `DOMContentLoaded` has already fired
else startup();

function startup() {
  setDefaultTimezone();
  ReactDOM.render(<App />, document.getElementById('root'));
}
